import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/admin/overview',
  },
  {
    path: '/admin/orders/packing_slip',
    name: 'packing_slip',
    component: () => import('../views/orders/Packing_slip.vue'),
    meta: {
      requiresAuth: true, // 需被驗證才能進入底下頁面
      title: '列印揀貨單',
    },
  },
  {
    path: '/admin',
    redirect: '/admin/overview',
    name: 'admin',
    component: () => import('../views/Admin.vue'),
    meta: {
      requiresAuth: true, // 需被驗證才能進入底下頁面
    },
    children: [
      {
        path: 'overview',
        name: 'overview',
        component: () => import('../views/Overview.vue'),
        meta: {
          title: '總覽',
        },
      },
      {
        path: 'manual',
        name: 'manual',
        component: () => import('../views/Manual.vue'),
        meta: {
          title: '開店手冊',
          routerViewContainer: true,
        },
      },
      {
        path: 'setting',
        name: 'setting',
        component: () => import('../views/Setting.vue'),
        meta: {
          title: '設定',
          routerViewContainer: true, // 判斷此頁面是否有限制寬度，去修改header title max-width
        },
      },
      {
        path: 'account',
        name: 'account',
        component: () => import('../views/Account.vue'),
        meta: {
          title: '帳號設定',
          routerViewContainer: true,
        },
      },

      {
        path: 'billing',
        name: 'billing',
        component: () => import('../views/Billing.vue'),
        meta: {
          title: '訂閱紀錄',
          routerViewContainer: true,
        },
      },

      {
        path: 'plans',
        name: 'plans',
        component: () => import('../views/Plans.vue'),
        meta: {
          title: '購買方案',
          routerViewContainer: true,
        },
      },
      {
        path: 'orders',
        name: 'orders',
        component: () => import('../views/orders/Orders.vue'),
        meta: {
          title: '所有訂單',
          sideMenuIndex: 1,
        },
      },
      {
        path: 'orders/:id',
        name: 'edit-order',
        component: () => import('../views/orders/Order.vue'),
        meta: {
          title: '訂單詳情',
          routerViewContainer: true,
          sideMenuIndex: 1,
        },
      },
      {
        path: 'create_order',
        name: 'create_order',
        component: () => import('../views/orders/Create_order.vue'),
        meta: {
          title: '創建訂單',
          sideMenuIndex: 1,
        },
      },
      {
        path: 'checkouts',
        name: 'checkouts',
        component: () => import('../views/orders/Checkouts.vue'),
        meta: {
          title: '待結帳訂單',
          sideMenuIndex: 1,
        },
      },
      {
        path: 'products',
        name: 'products',
        component: () => import('../views/products/Products.vue'),
        meta: {
          title: '所有商品',
          sideMenuIndex: 2,
        },
      },
      {
        path: 'products/new',
        name: 'new-product',
        component: () => import('../views/products/Product.vue'),
        meta: {
          title: '新增商品',
          routerViewContainer: true,
          sideMenuIndex: 2,
        },
      },
      {
        path: 'products/:id',
        name: 'edit-product',
        component: () => import('../views/products/Product.vue'),
        meta: {
          title: '編輯商品',
          routerViewContainer: true,
          sideMenuIndex: 2,
        },
      },
      {
        path: 'inventories',
        name: 'inventories',
        component: () => import('../views/products/Inventories.vue'),
        meta: {
          title: '庫存',
          sideMenuIndex: 2,
        },
      },
      {
        path: 'collections',
        name: 'collections',
        component: () => import('../views/products/Collections.vue'),
        meta: {
          title: '分類',
          routerViewContainer: true,
          sideMenuIndex: 2,
        },
      },
      {
        path: 'collections/new',
        name: 'new-collection',
        component: () => import('../views/products/Collection.vue'),
        meta: {
          title: '新增分類',
          routerViewContainer: true, // 判斷此頁面是否有限制寬度，去修改header title max-width
          sideMenuIndex: 2,
        },
      },
      {
        path: 'collections/:id',
        name: 'edit-collection',
        component: () => import('../views/products/Collection.vue'),
        meta: {
          title: '編輯分類',
          routerViewContainer: true,
          sideMenuIndex: 2,
        },
      },
      {
        path: 'add_items',
        name: 'add_items',
        component: () => import('../views/products/Add_items.vue'),
        meta: {
          title: '加購品',
          sideMenuIndex: 2,
        },
      },
      {
        path: 'freebies',
        name: 'freebies',
        component: () => import('../views/products/Freebies.vue'),
        meta: {
          title: '贈品',
          sideMenuIndex: 2,
        },
      },
      // {
      //   path: 'reviews',
      //   name: 'reviews',
      //   component: () => import('../views/products/Reviews.vue'),
      //   meta: {
      //     title: '評價',
      //      sideMenuIndex: 2,
      //   },
      // },
      {
        path: 'tags_vendors',
        name: 'tags_vendors',
        component: () => import('../views/products/Tags_vendors.vue'),
        meta: {
          title: '標籤和供應商',
          routerViewContainer: true,
          sideMenuIndex: 2,
        },
      },
      {
        path: 'vendors',
        name: 'vendors',
        component: () => import('../views/products/Vendors.vue'),
        meta: {
          title: '供應商',
          routerViewContainer: true,
          sideMenuIndex: 2,
        },
      },
      {
        path: 'psi_records',
        name: 'psi_records',
        component: () => import('../views/psi/Psi_records.vue'),
        meta: {
          title: '進銷存記錄',
          sideMenuIndex: 2,
        },
      },
      {
        path: 'inventories_import',
        name: 'inventories_import',
        component: () => import('../views/psi/Inventories_import.vue'),
        meta: {
          title: '匯入庫存',
          sideMenuIndex: 2,
        },
      },
      {
        path: 'customers',
        name: 'customers',
        component: () => import('../views/customers/Customers.vue'),
        meta: {
          title: '所有會員',
          sideMenuIndex: 3,
        },
      },
      {
        path: 'customers/new',
        name: 'new-customer',
        component: () => import('../views/customers/Customer.vue'),
        meta: {
          title: '新增會員',
          routerViewContainer: true,
          sideMenuIndex: 3,
        },
      },
      {
        path: 'customers/:id',
        name: 'edit-customer',
        component: () => import('../views/customers/Customer.vue'),
        meta: {
          title: '會員資料',
          routerViewContainer: true,
          sideMenuIndex: 3,
        },
      },
      {
        path: 'memberships',
        name: 'memberships',
        component: () => import('../views/customers/Memberships.vue'),
        meta: {
          title: '會員等級',
          sideMenuIndex: 3,
        },
      },
      {
        path: 'memberships/new',
        name: 'new-membership',
        component: () => import('../views/customers/Membership.vue'),
        meta: {
          title: '新增會員等級',
          routerViewContainer: true,
          sideMenuIndex: 3,
        },
      },
      {
        path: 'memberships/:id',
        name: 'edit-membership',
        component: () => import('../views/customers/Membership.vue'),
        meta: {
          title: '編輯會員等級',
          routerViewContainer: true,
          sideMenuIndex: 3,
        },
      },
      {
        path: 'giftvoucher',
        name: 'giftVoucher',
        component: () => import('../views/customers/GiftVoucher.vue'),
        meta: {
          title: '購物金',
          routerViewContainer: true,
          sideMenuIndex: 3,
        },
      },
      {
        path: 'giftvoucher/history',
        name: 'giftVoucher-history',
        component: () => import('../views/customers/GiftVoucher_history.vue'),
        meta: {
          title: '購物金發送紀錄',
          routerViewContainer: true,
          sideMenuIndex: 3,
        },
      },
      {
        path: 'rewardspoints',
        name: 'rewardsPoints',
        component: () => import('../views/customers/RewardsPoints.vue'),
        meta: {
          title: '點數回饋',
          routerViewContainer: true,
          sideMenuIndex: 3,
        },
      },
      {
        path: 'rewardspoints/history',
        name: 'rewardsPoints-history',
        component: () => import('../views/customers/RewardsPoints_history.vue'),
        meta: {
          title: '點數發送紀錄',
          routerViewContainer: true,
          sideMenuIndex: 3,
        },
      },
      {
        path: 'storewide_discounts',
        name: 'storewide_discounts',
        component: () => import('../views/promotions/Storewide_discounts.vue'),
        meta: {
          title: '全店優惠',
          sideMenuIndex: 4,
        },
      },
      {
        path: 'storewide_discounts/new',
        name: 'new-storewide_discount',
        component: () => import('../views/promotions/Storewide_discount.vue'),
        meta: {
          title: '新增全店優惠',
          routerViewContainer: true,
          sideMenuIndex: 4,
        },
      },
      {
        path: 'storewide_discounts/:id',
        name: 'edit-storewide_discount',
        component: () => import('../views/promotions/Storewide_discount.vue'),
        meta: {
          title: '編輯全店優惠',
          routerViewContainer: true,
          sideMenuIndex: 4,
        },
      },
      {
        path: 'product_discounts',
        name: 'product_discounts',
        component: () => import('../views/promotions/Product_discounts.vue'),
        meta: {
          title: '商品折扣',
          sideMenuIndex: 4,
        },
      },
      {
        path: 'product_discounts/new',
        name: 'new-product_discount',
        component: () => import('../views/promotions/Product_discount.vue'),
        meta: {
          title: '新增商品折扣',
          routerViewContainer: true,
          sideMenuIndex: 4,
        },
      },
      {
        path: 'product_discounts/:id',
        name: 'edit-product_discount',
        component: () => import('../views/promotions/Product_discount.vue'),
        meta: {
          title: '編輯商品折扣',
          routerViewContainer: true,
          sideMenuIndex: 4,
        },
      },
      {
        path: 'free_shipping',
        name: 'free_shippings',
        component: () => import('../views/promotions/Free_shippings.vue'),
        meta: {
          title: '滿額免運',
          sideMenuIndex: 4,
        },
      },
      {
        path: 'free_shipping/new',
        name: 'new-free_shipping',
        component: () => import('../views/promotions/Free_shipping.vue'),
        meta: {
          title: '新增滿額免運',
          routerViewContainer: true,
          sideMenuIndex: 4,
        },
      },
      {
        path: 'free_shipping/:id',
        name: 'edit-free_shipping',
        component: () => import('../views/promotions/Free_shipping.vue'),
        meta: {
          title: '編輯滿額免運',
          routerViewContainer: true,
          sideMenuIndex: 4,
        },
      },
      {
        path: 'coupons',
        name: 'coupons',
        component: () => import('../views/promotions/Coupons.vue'),
        meta: {
          title: '優惠碼',
          sideMenuIndex: 4,
        },
      },
      {
        path: 'coupons/new',
        name: 'new-coupon',
        component: () => import('../views/promotions/Coupon.vue'),
        meta: {
          title: '新增優惠碼',
          routerViewContainer: true,
          sideMenuIndex: 4,
        },
      },
      {
        path: 'coupons/:id',
        name: 'edit-coupon',
        component: () => import('../views/promotions/Coupon.vue'),
        meta: {
          title: '編輯優惠碼',
          routerViewContainer: true,
          sideMenuIndex: 4,
        },
      },

      {
        path: 'themes',
        name: 'themes',
        component: () => import('../views/web_setting/Themes.vue'),
        meta: {
          title: '佈景主題',
          routerViewContainer: true,
          sideMenuIndex: 99,
        },
      },
      {
        path: 'menus',
        name: 'menus',
        component: () => import('../views/web_setting/menus/Menus.vue'),
        meta: {
          title: '目錄選單',
          routerViewContainer: true,
          sideMenuIndex: 99,
        },
      },
      {
        path: 'menus/header',
        name: 'menus-header',
        component: () => import('../views/web_setting/menus/Header.vue'),
        meta: {
          title: '頁首選單',
          routerViewContainer: true,
          sideMenuIndex: 99,
        },
      },
      {
        path: 'menus/footer',
        name: 'menus-footer',
        component: () => import('../views/web_setting/menus/Footer.vue'),
        meta: {
          title: '頁尾選單',
          routerViewContainer: true,
          sideMenuIndex: 99,
        },
      },
      {
        path: 'pages',
        name: 'pages',
        component: () => import('../views/web_setting/page/Pages.vue'),
        meta: {
          title: '網站分頁',
          sideMenuIndex: 99,
        },
      },
      {
        path: 'pages/new',
        name: 'new-page',
        component: () => import('../views/web_setting/page/Page.vue'),
        meta: {
          title: '新增分頁',
          routerViewContainer: true, // 判斷此頁面是否有限制寬度，去修改header title max-width
          sideMenuIndex: 99,
        },
      },
      {
        path: 'pages/:id',
        name: 'edit-page',
        component: () => import('../views/web_setting/page/Page.vue'),
        meta: {
          title: '編輯分頁',
          routerViewContainer: true,
          sideMenuIndex: 99,
        },
      },
      {
        path: 'blogs',
        name: 'blogs',
        component: () => import('../views/web_setting/blog/Blogs.vue'),
        meta: {
          title: '管理部落格',
          sideMenuIndex: 99,
        },
      },
      {
        path: 'blogs/new',
        name: 'new-blog',
        component: () => import('../views/web_setting/blog/Blog.vue'),
        meta: {
          title: '新增部落格',
          routerViewContainer: true,
          sideMenuIndex: 99,
        },
      },

      {
        path: 'blogs/posts',
        name: 'posts',
        component: () => import('../views/web_setting/blog/Posts.vue'),
        meta: {
          title: '部落格文章',
          sideMenuIndex: 99,
        },
      },
      {
        path: 'blogs/:id',
        name: 'edit-blog',
        component: () => import('../views/web_setting/blog/Blog.vue'),
        meta: {
          title: '編輯部落格',
          routerViewContainer: true,
          sideMenuIndex: 99,
        },
      },
      {
        path: 'comments',
        name: 'comments',
        component: () => import('../views/web_setting/blog/Comments.vue'),
        meta: {
          title: '管理留言',
          sideMenuIndex: 99,
        },
      },
      {
        path: 'blogs/posts/new',
        name: 'new-post',
        component: () => import('../views/web_setting/blog/Post.vue'),
        meta: {
          title: '新增文章',
          routerViewContainer: true,
          sideMenuIndex: 99,
        },
      },
      {
        path: 'blogs/posts/:id',
        name: 'edit-post',
        component: () => import('../views/web_setting/blog/Post.vue'),
        meta: {
          title: '編輯文章',
          routerViewContainer: true,
          sideMenuIndex: 99,
        },
      },
      {
        path: 'setting/basic_general',
        name: 'basic_general',
        component: () => import('../views/settings/basic/General.vue'),
        meta: {
          title: '商店資料',
          routerViewContainer: true,
        },
      },
      {
        path: 'setting/basic_account',
        name: 'basic_account',
        component: () => import('../views/settings/basic/Account.vue'),
        meta: {
          title: '使用者權限',
        },
      },
      {
        path: 'setting/domains',
        name: 'setting_domains',
        component: () => import('../views/settings/Domains.vue'),
        meta: {
          title: '網域設定',
          routerViewContainer: true,
        },
      },
      {
        path: 'setting/web_manager',
        name: 'setting_web_manager',
        component: () => import('../views/settings/Web_manager.vue'),
        meta: {
          title: '網站管理工具',
          routerViewContainer: true,
        },
      },
      {
        path: 'setting/store_notifications',
        name: 'store_notifications',
        component: () => import('../views/settings/store/Notifications.vue'),
        meta: {
          title: '通知設定',
        },
      },
      {
        path: 'setting/store_languages',
        name: 'store_languages',
        component: () => import('../views/settings/store/Languages.vue'),
        meta: {
          title: '語系設定',
        },
      },

      {
        path: 'setting/store_checkout',
        name: 'store_checkout',
        component: () => import('../views/settings/store/Checkout.vue'),
        meta: {
          title: '結帳設定',
        },
      },
      {
        path: 'setting/store_shipping',
        name: 'store_shipping',
        component: () => import('../views/settings/store/Shipping.vue'),
        meta: {
          title: '物流設定',
          routerViewContainer: true,
        },
      },
      {
        path: 'setting/store_shipping/shipping/new',
        name: 'new-shipping',
        component: () => import('../views/settings/shipping/Shipping.vue'),
        meta: {
          title: '新增自訂運送方式',
          routerViewContainer: true,
        },
      },
      {
        path: 'setting/store_shipping/shipping/:id',
        name: 'edit-shipping',
        component: () => import('../views/settings/shipping/Shipping.vue'),
        meta: {
          title: '編輯自訂運送方式',
          routerViewContainer: true,
        },
      },
      {
        path: 'setting/store_shipping/ecpay',
        name: 'ecpay-shipping',
        component: () => import('../views/settings/shipping/Ecpay.vue'),
        meta: {
          title: '綠界物流服務',
          routerViewContainer: true,
        },
      },
      {
        path: 'setting/store_shipping/ezship',
        name: 'ezship',
        component: () => import('../views/settings/shipping/Ezship.vue'),
        meta: {
          title: 'ezship物流服務',
          routerViewContainer: true,
        },
      },
      {
        path: 'setting/store_payments',
        name: 'store_payments',
        component: () => import('../views/settings/store/Payments.vue'),
        meta: {
          title: '金流設定',
          routerViewContainer: true,
        },
      },
      {
        path: 'setting/store_payments/bank_transfer',
        name: 'bank_transfer',
        component: () => import('../views/settings/payments/BankTransfer.vue'),
        meta: {
          title: '自訂銀行轉帳',
          routerViewContainer: true,
        },
      },
      {
        path: 'setting/store_payments/ecpay',
        name: 'ecpay-payment',
        component: () => import('../views/settings/payments/Ecpay.vue'),
        meta: {
          title: '綠界金流服務',
          routerViewContainer: true,
        },
      },
      {
        path: 'setting/store_payments/newebpay',
        name: 'newebpay-payment',
        component: () => import('../views/settings/payments/NewebPay.vue'),
        meta: {
          title: '藍新金流服務',
          routerViewContainer: true,
        },
      },
      {
        path: 'setting/extension_billing',
        name: 'extension_billing',
        component: () => import('../views/settings/extension/Billing.vue'),
        meta: {
          title: '電子發票',
          routerViewContainer: true,
        },
      },
      {
        path: 'setting/extension_google',
        name: 'extension_google',
        component: () => import('../views/settings/extension/Google.vue'),
        meta: {
          title: 'Google',
          routerViewContainer: true,
        },
      },
      {
        path: 'setting/extension_facebook',
        name: 'extension_facebook',
        component: () => import('../views/settings/extension/Facebook.vue'),
        meta: {
          title: 'Facebook',
          routerViewContainer: true,
        },
      },
      {
        path: 'setting/extension_line',
        name: 'extension_line',
        component: () => import('../views/settings/extension/Line.vue'),
        meta: {
          title: 'LINE',
          routerViewContainer: true,
        },
      },
      {
        path: 'setting/extension_message',
        name: 'extension_message',
        component: () => import('../views/settings/extension/Message.vue'),
        meta: {
          title: '簡訊',
          routerViewContainer: true,
        },
      },
    ],
  },
  {
    path: '/thirdlogistics',
    name: 'thirdlogistics',
    component: () => import('../views/Thirdlogistics.vue'),
    meta: {
      requiresAuth: true, // 需被驗證才能進入底下頁面
    },
  },
  {
    path: '/admin/themes/edit/:id',
    name: 'edit-theme',
    component: () => import('../views/web_setting/EditTheme.vue'),
    meta: {
      requiresAuth: true, // 需被驗證才能進入底下頁面
      title: '編輯佈景主題',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: '登入',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Register.vue'),
    meta: {
      title: '註冊開店',
    },
  },
  {
    path: '/mailcerti',
    name: 'mailcerti',
    component: () => import('../views/MailCerti.vue'),
    meta: {
      title: '信箱驗證',
    },
  },
  {
    path: '/mailforget',
    name: 'mailforget',
    component: () => import('../views/MailForget.vue'),
    meta: {
      title: '重設忘記密碼',
    },
  },
  {
    path: '/404notfound',
    name: '404',
    component: () => import('../views/404NotFound.vue'),
    meta: {
      title: '404 Not Found',
    },
  },
  {
    path: '*',
    redirect: '/404notfound',
  },
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  // base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0, behavior: 'smooth' }
    }
  },
})

// 路由跳轉前調用
router.beforeEach((to, from, next) => {
  // 若進到需驗證的頁面
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let stoken = store.state.login.userInfo.stoken
    if (stoken) {
      // 1.3.1 讀取網站參數
      Vue.prototype
        .$axios({
          url: 'front/overview/getWebParam.php',
          headers: {
            stoken,
          },
          kcval: true,
          data: {
            lang: 0,
          },
        })
        .then((res) => {
          if (res.data.Success) {
            // 儲存網站參數
            store.commit('getWebData/UPDATE_COOKIE', res.data.Data)
          } else {
            console.error(res.data.Message)
          }
        })
      next()
    } else {
      // 導向回登入並附帶當前網址，方便登入成功後再導回來
      next({
        name: 'login',
        query: { redirect: to.fullPath },
      })
    }
  }

  // 若進到登入或註冊頁面
  if (to.name === 'login' || to.name === 'register') {
    // 若已經登入了，跳轉到admin
    let stoken = store.state.login.userInfo.stoken
    if (stoken) {
      next({
        name: 'admin',
        replace: true,
      })
    } else {
      next()
    }
  }

  next()
})

// 路由跳轉後調用
// 不會有 next 參數，因此不會影響路由的跳轉。 但是多了一個 failure 參數用來表示路由跳轉失敗。
router.afterEach((to, from, failure) => {
  document.title = `${to.meta.title} | Admin `

  // if (!failure) { // 若路由跳轉成功
  //   sendToAnalytics(to.fullPath) // 追蹤 GA 等工具
  // } else {
  //   // fallback...
  // }
})

export default router
